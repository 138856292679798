import React from "react";
import PageWrapper from "../components/PageWrapper";
import Content from "../sections/exo/exomesocontent.js";
import Prprecovery from "../sections/exo/exoglance.js";
import Faq from "../sections/exo/exomesofaq.js";
import Brand from "../sections/homepage/Brands.js";
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Testimonial from "../sections/index/indexreviews.js";
import Trend from '../components/trends.js';
import Content2 from "../sections/exo/exomesocontent2.js";
import Content3 from "../sections/exo/exocontent3.js";
import Content4 from "../sections/exo/exopackage.js";
import Content5 from "../sections/exo/exocontent4.js"
import Saveface from '../components/save.js';
import Clinic from '../components/NewFind.js'; 
import Award from "../components/NewToxane.js";
import HeroComponent from "../components/ImageHero.js";
import imgVideo from "../assets/image/jpeg/exosomes-hair-loss-treatment.jpg";
import Glance from "../components/glance.js";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";

const titleText = "Exosomes For Hair Loss";
const textContent = "Unlock the Secrets to Fuller, Healthier Hair with Exosome";

const ExoLossPRPPage = () => {
  return (
    <>
            <Head title="ExoMeso Hair Treatment London" image="/images/dermamina-home.jpg"  description="Discover Exo Meso, a revitalizing hair treatment targeting hair loss and strengthening roots. Boost scalp health, promote growth, and restore hair confidence." keywords="Exo Meso, hair loss treatment, scalp health, hair restoration, alopecia solution, hair growth, trichology, strengthen hair roots, DHT blocker, improve hair density"/>

            <JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'PRP Hair Loss',
item:'https://www.dermamina.com/prp-for-hair-loss',
},

]
}}
</JsonLd>

      <PageWrapper headerDark>

      <HeroComponent
    itext='<span style="color: #1a1a1a;">ExoMeso</span> <span style="color: #00aec7;">Hair Therapy</span>'
    secondText="Revitalise Roots, Strengthen Hair, Restore Confidence."
    videoAlt="ExoMEso Hair Loss"
    imgVideo={imgVideo} // Specify your image URL here
  />

<Section py={0} className="pt-lg-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
  <Glance  best="6 Sessions"
  bestalt="best Exosomes Hair Loss"
  resultstext="Long Lasting"
  resultsalt="Exosomes Hair Loss results"
  costdata="£240"
  costalt="Exosomes Hair Loss Cost"
  timedata="40 Minutes"
  timealt="Exosomes Hair Loss Duration Time"
  workdata="Immediately"
  workalt="Exosomes Hair Loss downtime work"
  paindata="None / Minimum"
  painalt="Exosomes Hair Loss Pain"/>
 </Col>
  </Row>
  </Container>
  </Section>
  <Testimonial /> 
<Content/>

<Content2 />
<Award />


<Faq />
   

<Saveface />
<Trend /> 

      </PageWrapper>
     
    </>
  );
};
export default ExoLossPRPPage;
